/* React & TailwindCSS Portfolio Main CSS File */

body {
	font-family: 'GeneralSans-Variable';
}

/* Fonts */
@font-face {
	font-family: 'GeneralSans-Variable';
	src: url('../fonts/GeneralSans-Variable.woff2') format('woff2'),
		url('../fonts/GeneralSans-Variable.woff') format('woff'),
		url('../fonts/GeneralSans-Variable.ttf') format('truetype');
	font-weight: 200 700;
	font-display: swap;
	font-style: normal;
}

.font-general-variable {
	font-family: 'GeneralSans-Variable';
}

@font-face {
	font-family: 'GeneralSans-VariableItalic';
	src: url('../fonts/GeneralSans-VariableItalic.woff2') format('woff2'),
		url('../fonts/GeneralSans-VariableItalic.woff') format('woff'),
		url('../fonts/GeneralSans-VariableItalic.ttf') format('truetype');
	font-weight: 200 700;
	font-display: swap;
	font-style: italic;
}

.font-general-variable-italic {
	font-family: 'GeneralSans-VariableItalic';
}

@font-face {
	font-family: 'GeneralSans-Extralight';
	src: url('../fonts/GeneralSans-Extralight.woff2') format('woff2'),
		url('../fonts/GeneralSans-Extralight.woff') format('woff'),
		url('../fonts/GeneralSans-Extralight.ttf') format('truetype');
	font-weight: 200;
	font-display: swap;
	font-style: normal;
}

.font-general-extralight {
	font-family: 'GeneralSans-Extralight';
}

@font-face {
	font-family: 'GeneralSans-ExtralightItalic';
	src: url('../fonts/GeneralSans-ExtralightItalic.woff2') format('woff2'),
		url('../fonts/GeneralSans-ExtralightItalic.woff') format('woff'),
		url('../fonts/GeneralSans-ExtralightItalic.ttf') format('truetype');
	font-weight: 200;
	font-display: swap;
	font-style: italic;
}

.font-general-extralight-italic {
	font-family: 'GeneralSans-ExtralightItalic';
}

@font-face {
	font-family: 'GeneralSans-Light';
	src: url('../fonts/GeneralSans-Light.woff2') format('woff2'),
		url('../fonts/GeneralSans-Light.woff') format('woff'),
		url('../fonts/GeneralSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}

.font-general-light {
	font-family: 'GeneralSans-Light';
}

@font-face {
	font-family: 'GeneralSans-LightItalic';
	src: url('../fonts/GeneralSans-LightItalic.woff2') format('woff2'),
		url('../fonts/GeneralSans-LightItalic.woff') format('woff'),
		url('../fonts/GeneralSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-display: swap;
	font-style: italic;
}

.font-general-light-italic {
	font-family: 'GeneralSans-LightItalic';
}

@font-face {
	font-family: 'GeneralSans-Regular';
	src: url('../fonts/GeneralSans-Regular.woff2') format('woff2'),
		url('../fonts/GeneralSans-Regular.woff') format('woff'),
		url('../fonts/GeneralSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

.font-general-regular {
	font-family: 'GeneralSans-Regular';
}

@font-face {
	font-family: 'GeneralSans-Italic';
	src: url('../fonts/GeneralSans-Italic.woff2') format('woff2'),
		url('../fonts/GeneralSans-Italic.woff') format('woff'),
		url('../fonts/GeneralSans-Italic.ttf') format('truetype');
	font-weight: 400;
	font-display: swap;
	font-style: italic;
}

.font-general-italic {
	font-family: 'GeneralSans-Italic';
}

@font-face {
	font-family: 'GeneralSans-Medium';
	src: url('../fonts/GeneralSans-Medium.woff2') format('woff2'),
		url('../fonts/GeneralSans-Medium.woff') format('woff'),
		url('../fonts/GeneralSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

.font-general-medium {
	font-family: 'GeneralSans-Medium';
}

@font-face {
	font-family: 'GeneralSans-MediumItalic';
	src: url('../fonts/GeneralSans-MediumItalic.woff2') format('woff2'),
		url('../fonts/GeneralSans-MediumItalic.woff') format('woff'),
		url('../fonts/GeneralSans-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-display: swap;
	font-style: italic;
}

.font-general-medium-italic {
	font-family: 'GeneralSans-MediumItalic';
}

@font-face {
	font-family: 'GeneralSans-Semibold';
	src: url('../fonts/GeneralSans-Semibold.woff2') format('woff2'),
		url('../fonts/GeneralSans-Semibold.woff') format('woff'),
		url('../fonts/GeneralSans-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}

.font-general-semibold {
	font-family: 'GeneralSans-Semibold';
}

@font-face {
	font-family: 'GeneralSans-SemiboldItalic';
	src: url('../fonts/GeneralSans-SemiboldItalic.woff2') format('woff2'),
		url('../fonts/GeneralSans-SemiboldItalic.woff') format('woff'),
		url('../fonts/GeneralSans-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-display: swap;
	font-style: italic;
}

.font-general-semibold-italic {
	font-family: 'GeneralSans-SemiboldItalic';
}

@font-face {
	font-family: 'GeneralSans-Bold';
	src: url('../fonts/GeneralSans-Bold.woff2') format('woff2'),
		url('../fonts/GeneralSans-Bold.woff') format('woff'),
		url('../fonts/GeneralSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

.font-general-bold {
	font-family: 'GeneralSans-Bold';
}

@font-face {
	font-family: 'GeneralSans-BoldItalic';
	src: url('../fonts/GeneralSans-BoldItalic.woff2') format('woff2'),
		url('../fonts/GeneralSans-BoldItalic.woff') format('woff'),
		url('../fonts/GeneralSans-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-display: swap;
	font-style: italic;
}

.font-general-bold-italic {
	font-family: 'GeneralSans-BoldItalic';
}

/* Scroll to top style */
.scrollToTop {
	/* @apply bg-indigo-600;
	@apply text-white; */
	position: fixed;
	width: 100%;
	align-items: center;
	height: 20px;
	justify-content: center;
	z-index: 999;
	cursor: pointer;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 0.9;
}

.scrollToTop:hover {
	opacity: 1;
}

.tooltip {
	position: relative;
	background: rgba(0, 0, 0, 0.7);
	color: white;
	visibility: hidden;
	padding: 5px;
	border-radius: 5px;
	max-height: 2rem;
  }
  .tooltip-arrow {
	position: absolute;
	top: 100%;
	left: 50%;
	border-width: 5px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.7) transparent transparent;
  }

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.5;
	}
}

/* CHAT */

.chatIcon {

	height: auto; 
    width: 6rem;
    border-radius: 50px;
    right: 50px;
    bottom: 50px;
    display: flex;
    padding: 5px;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 999;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.9;
	margin-bottom: 2rem;
	visibility: hidden;
}

.chatForm {
	position: fixed;
	bottom: 0rem;
	right: 0;
  }

.chatMessages {

    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 120px 20px;
	background-color: whitesmoke;

    height: calc(408px);
    overflow-y: scroll;
    margin-top: 2px;
    padding-top: 6px;

	/* max-width: 21rem; */
}

.chatRobotRow {
	display: flex;
    flex-direction: row;
    align-items: flex-start;
	font-size: 14px;
}

.chatOptions {

	animation: 0.3s ease 0s 1 normal forwards running Lmuha;
    cursor: pointer;
    /* display: inline-block; */
	margin: 1rem;
    /* transform: scale(0); */

	background: linear-gradient(to right, rgb(0, 77, 205) 5%, rgba(0, 77, 205, 0.6));
    border-radius: 22px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    color: rgb(255, 255, 255);
    display: inline-block;
    font-size: 12px;
    padding: 12px;

	text-decoration: none;
    background: rgb(255, 255, 255);
    color: rgb(0, 77, 205);
    box-shadow: rgb(0, 77, 205) 0px 0px 0px 1.5px;

	max-width: 6rem;

}

.chatUser {
	animation: 0.3s ease 0s 1 normal forwards running Lmuha;
    background: rgb(255, 255, 255);
    border-radius: 18px 18px 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    color: rgb(74, 74, 74);
    display: inline-block;
    font-size: 14px;
    max-width: 50%;
    margin: 10px 0px 10px;
    overflow: hidden;
    position: relative;
    padding: 12px;
    /* transform: scale(0); */
    transform-origin: right bottom;
}

/* @media (min-width: 768px) {
	.chatIcon {
		visibility: visible;
	}
} */
  /* END CHAT */